import React, { useState } from 'react';

import SecurityCamera from './SecurityCamera';
import ViewSecurityCameraModal from './ViewSecurityCameraModal';

const SecurityCameras = ({
  projectId,
  cameras,
  showTrueLookIframe,
  showCheckvideoIframe,
}) => {
  const [openCamera, setOpenCamera] = useState(null);

  if (!cameras.length && !showTrueLookIframe && !showCheckvideoIframe)
    return (
      <div className="empty-security-cameras">
        Must configure security cameras first...
      </div>
    );

  return (
    <>
      <div
        className="security-cameras"
        style={{
          gridTemplateColumns: `repeat(${
            cameras.length < 4 ? cameras.length : 4
          }, 1fr)`,
          gap: `${cameras.length === 2 ? 24 : 16}px`,
        }}
      >
        {cameras.map((camera) => (
          <SecurityCamera
            key={camera.channel}
            projectId={projectId}
            camera={camera}
            autoreload={!openCamera}
            onClick={() => setOpenCamera(camera)}
          />
        ))}
      </div>
      {openCamera && (
        <ViewSecurityCameraModal
          projectId={projectId}
          camera={openCamera}
          onClose={() => setOpenCamera(null)}
        />
      )}
    </>
  );
};

export default SecurityCameras;
