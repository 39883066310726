import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import Menu, { MENU_SELECTOR } from 'components/shared/menu/Menu';

import SecurityCameraPermissions from 'permissions/security-camera-permissions';

import { getDarkMode } from 'selectors/theme';
import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';

import {
  getSecurityCamerasConfiguration,
  getSecurityCamerasConfigurationForm,
  getSecurityCamerasCamerasForm,
  getSecurityCamerasPermissionsForm,
  getShowTrueLookIframe,
  getShowCheckvideoIframe,
} from './store/selectors';
import {
  setSecurityCameraConfiguration,
  loadSecurityCameraConfiguration,
  loadSecurityCameraConfigurationForm,
  loadSecurityCameraPermissionsForm,
  loadSecurityCameraCamerasForm,
} from './store/actions';
import SecurityCameras from './SecurityCameras';
import ConfigureModal from './ConfigureModal';
import PermissionsModal from './PermissionsModal';
import SecurityCamerasModal from './SecurityCamerasModal';
import './ProjectSecurityCameras.css';
import Iframe from 'react-iframe';

const ProjectSecurityCameras = (props) => {
  const {
    dispatch,
    renderHeaders,
    darkMode,
    currentUser,
    currentProject,
    configuration,
    configurationForm,
    camerasForm,
    permissionsForm,
    showTrueLookIframe,
    showCheckvideoIframe,
  } = props;

  const trueLookIframeRef = useRef(null);
  const checkVideoIframeRef = useRef(null);
  const headerButtons = () => {
    const menuItems = [
      SecurityCameraPermissions.canConfigure(currentUser, currentProject) && {
        content: 'Configure',
        onClick: () =>
          dispatch(loadSecurityCameraConfigurationForm(currentProject.id)),
      },
      SecurityCameraPermissions.canEditPermissions(
        currentUser,
        currentProject,
        configuration
      ) && {
        content: 'Permissions',
        onClick: () =>
          dispatch(loadSecurityCameraPermissionsForm(currentProject.id)),
      },
      SecurityCameraPermissions.canEditCameras(
        currentUser,
        currentProject,
        configuration
      ) && {
        content: 'Select Cameras',
        onClick: () =>
          dispatch(loadSecurityCameraCamerasForm(currentProject.id)),
      },
    ].filter((item) => item);

    return [
      menuItems.length > 0 && (
        <Menu
          key="menu"
          selector={MENU_SELECTOR.MORE_OPTIONS_VERTICAL}
          items={menuItems}
        />
      ),
    ].filter((button) => button);
  };

  if (!currentProject) return null;

  useEffect(() => {
    dispatch(loadSecurityCameraConfiguration(currentProject.id));

    return () => {
      dispatch(setSecurityCameraConfiguration());
    };
  }, []);

  useEffect(() => {
    if (showTrueLookIframe && trueLookIframeRef.current) {
      // Scroll to the wrapper when iframe is rendered
      trueLookIframeRef.current.scrollIntoView({
        behavior: 'smooth', // Smooth scrolling, I think it's the best
      });
    }
  }, [showTrueLookIframe]);

  useEffect(() => {
    console.log('showCheckvideoIframe', showCheckvideoIframe);
    if (showCheckvideoIframe && checkVideoIframeRef.current) {
      // Scroll to the wrapper when iframe is rendered
      checkVideoIframeRef.current.scrollIntoView({
        behavior: 'smooth', // Smooth scrolling, I think it's the best
      });
    }
  }, [showCheckvideoIframe]);

  return (
    <div
      className={classnames('security-cameras-content-container', {
        'dark-mode': darkMode,
      })}
    >
      {renderHeaders(headerButtons())}
      <div className="content-container">
        <SecurityCameras
          projectId={currentProject.id}
          cameras={(configuration && configuration.security_cameras) || []}
          showTrueLookIframe={showTrueLookIframe}
          showCheckvideoIframe={showCheckvideoIframe}
        />
      </div>
      <div ref={trueLookIframeRef}>
        {showTrueLookIframe && (
          <Iframe
            className="cameras-iframe"
            url="https://app.truelook.cloud/login"
            width="100%"
            height="100%"
            display="block"
            position="relative"
            allowFullScreen
          />
        )}
        <div ref={checkVideoIframeRef}>
          {showCheckvideoIframe && (
            <Iframe
              className="cameras-iframe"
              url="https://portal.checkvideo.net/"
              width="100%"
              height="100%"
              display="block"
              position="relative"
              allowFullScreen
            />
          )}
        </div>
      </div>
      {configurationForm && <ConfigureModal />}
      {permissionsForm && <PermissionsModal />}
      {camerasForm && <SecurityCamerasModal />}
    </div>
  );
};

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
    currentProject: getCurrentProject(state),
    configuration: getSecurityCamerasConfiguration(state),
    configurationForm: getSecurityCamerasConfigurationForm(state),
    camerasForm: getSecurityCamerasCamerasForm(state),
    permissionsForm: getSecurityCamerasPermissionsForm(state),
    darkMode: getDarkMode(state),
    showTrueLookIframe: getShowTrueLookIframe(state),
    showCheckvideoIframe: getShowCheckvideoIframe(state),
  };
})(ProjectSecurityCameras);
