export const SECURITY_CAMERA_CONNECTORS = [
  { value: '', label: 'Disabled' },
  { value: 'hikvision', label: 'Hikvision' },
  { value: 'truelook', label: 'True Look' },
  { value: 'checkvideo', label: 'CheckVideo' },
];

class SecurityCamera {
  isConfigured = (configuration) =>
    !!(configuration && configuration.connector);
}

export default new SecurityCamera();
