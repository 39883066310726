import { securityCamerasApi } from 'services/api/security-cameras-api';
import { showErrorMessage } from 'actions/messages';
import {
  SET_SECURITY_CAMERA_CONFIGURATION,
  SET_SECURITY_CAMERA_CONFIGURATION_FORM,
  SET_SECURITY_CAMERA_CONFIGURATION_FORM_VALUE,
  SET_SECURITY_CAMERA_CAMERAS_FORM,
  SET_SECURITY_CAMERA_CAMERAS_FORM_VALUE,
  SET_SECURITY_CAMERA_PERMISSIONS_FORM,
  SET_SECURITY_CAMERA_PERMISSIONS_FORM_VALUE,
  SET_SHOW_TRUE_LOOK_IFRAME,
  SET_SHOW_CHECKVIDEO_LOOK_IFRAME,
} from './action-types';

// data state

export const setSecurityCameraConfiguration = (configuration) => {
  return { type: SET_SECURITY_CAMERA_CONFIGURATION, payload: configuration };
};

// ui state

export const setSecurityCameraConfigurationForm = (configuration = null) => {
  return {
    type: SET_SECURITY_CAMERA_CONFIGURATION_FORM,
    payload: configuration,
  };
};

export const setSecurityCameraConfigurationFormValue = (name, value) => {
  return {
    type: SET_SECURITY_CAMERA_CONFIGURATION_FORM_VALUE,
    payload: { name, value },
  };
};

export const setSecurityCameraCamerasForm = (configuration = null) => {
  return {
    type: SET_SECURITY_CAMERA_CAMERAS_FORM,
    payload: configuration,
  };
};

export const setSecurityCameraCamerasFormValue = (index, name, value) => {
  return {
    type: SET_SECURITY_CAMERA_CAMERAS_FORM_VALUE,
    payload: { index, name, value },
  };
};

export const setSecurityCameraPermissionsForm = (configuration = null) => {
  return {
    type: SET_SECURITY_CAMERA_PERMISSIONS_FORM,
    payload: configuration,
  };
};

export const setSecurityCameraPermissionsFormValue = (name, value) => {
  return {
    type: SET_SECURITY_CAMERA_PERMISSIONS_FORM_VALUE,
    payload: { name, value },
  };
};

export const setShowTrueLookIframe = (showTrueLookIframe) => {
  return {
    type: SET_SHOW_TRUE_LOOK_IFRAME,
    payload: { showTrueLookIframe },
  };
};

export const setShowCheckvideoIframe = (showCheckvideoIframe) => {
  return {
    type: SET_SHOW_CHECKVIDEO_LOOK_IFRAME,
    payload: { showCheckvideoIframe },
  };
};

// load requests

const promiseResponsesOk = (responses) =>
  responses.every((response) => response.ok);

export const loadSecurityCameraConfiguration = (
  projectId,
  action = setSecurityCameraConfiguration
) => {
  return (dispatch) => {
    return securityCamerasApi.get(projectId).then((response) => {
      if (response.ok && action) {
        dispatch(action(response.configuration || {}));
      } else if (!response.ok) {
        dispatch(showErrorMessage(response.errors));
      }
      return response;
    });
  };
};

export const loadSecurityCameraConfigurationForm = (projectId) =>
  loadSecurityCameraConfiguration(
    projectId,
    setSecurityCameraConfigurationForm
  );

export const loadSecurityCameraPermissionsForm = (projectId) =>
  loadSecurityCameraConfiguration(projectId, setSecurityCameraPermissionsForm);

export const loadSecurityCameraCamerasForm = (projectId) => {
  return (dispatch) => {
    return Promise.all([
      securityCamerasApi.get(projectId),
      securityCamerasApi.preview(projectId),
    ]).then((responses) => {
      if (promiseResponsesOk(responses)) {
        dispatch(
          setSecurityCameraCamerasForm({
            configuration: responses[0].configuration,
            preview: responses[1].preview,
          })
        );
      } else {
        dispatch(
          showErrorMessage(responses.find((response) => !response.ok).errors)
        );
      }
      return responses;
    });
  };
};

// store requests

const isWizzard = (configuration) =>
  !!configuration.connector && configuration.security_cameras.length === 0;

export const saveSecurityCameraConfiguration = (projectId, values) => {
  return (dispatch) => {
    return securityCamerasApi
      .saveConfiguration(projectId, values)
      .then((response) => {
        if (response.ok) {
          dispatch(setSecurityCameraConfiguration(response.configuration));
          dispatch(setSecurityCameraConfigurationForm());
          if (isWizzard(response.configuration))
            dispatch(loadSecurityCameraPermissionsForm(projectId));
        } else {
          dispatch(showErrorMessage('Camera configuration is invalid'));
        }
        return response;
      });
  };
};

export const saveSecurityCameraPermissions = (projectId, values) => {
  return (dispatch) => {
    return securityCamerasApi
      .savePermissions(projectId, values)
      .then((response) => {
        if (response.ok) {
          dispatch(setSecurityCameraConfiguration(response.configuration));
          dispatch(setSecurityCameraPermissionsForm());
          if (isWizzard(response.configuration))
            dispatch(loadSecurityCameraCamerasForm(projectId));
        } else {
          dispatch(showErrorMessage(response.errors));
        }
        return response;
      });
  };
};

export const saveSecurityCameraCameras = (projectId, values) => {
  return (dispatch) => {
    return securityCamerasApi
      .saveCameras(projectId, values)
      .then((response) => {
        if (response.ok) {
          dispatch(setSecurityCameraConfiguration(response.configuration));
          dispatch(setSecurityCameraCamerasForm());
        } else {
          dispatch(showErrorMessage(response.errors));
        }
        return response;
      });
  };
};
