import {
  SET_SECURITY_CAMERA_CONFIGURATION_FORM,
  SET_SECURITY_CAMERA_CONFIGURATION_FORM_VALUE,
  SET_SECURITY_CAMERA_CAMERAS_FORM,
  SET_SECURITY_CAMERA_CAMERAS_FORM_VALUE,
  SET_SECURITY_CAMERA_PERMISSIONS_FORM,
  SET_SECURITY_CAMERA_PERMISSIONS_FORM_VALUE,
  SET_SHOW_TRUE_LOOK_IFRAME,
  SET_SHOW_CHECKVIDEO_LOOK_IFRAME,
} from './action-types';

const initialState = {
  configurationForm: null,
  camerasForm: null,
  permissionsForm: null,
};

const makeConfigurationForm = ({ connector }) => {
  if (!connector) return {};

  const { name, url, username } = connector;
  return {
    connector: name,
    url,
    username,
  };
};

const makeCamerasForm = ({
  configuration: { security_cameras: cameras },
  preview,
}) => {
  const selectedCameras = cameras.reduce(
    (selected, camera) => ({ ...selected, [camera.channel]: camera }),
    {}
  );
  return {
    cameras: preview.map(({ channel, name, picture }) => {
      const selectedCamera = selectedCameras[channel];
      return {
        channel,
        picture,
        name: (selectedCamera && selectedCamera.name) || name,
        description: (selectedCamera && selectedCamera.description) || '',
        selected: !!selectedCamera,
      };
    }),
  };
};

const makePermissionsForm = ({ permission, permission_users: users }) => ({
  permission,
  users,
});

export default function projectPhotosUiReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SECURITY_CAMERA_CONFIGURATION_FORM:
      return {
        ...state,
        configurationForm: action.payload
          ? makeConfigurationForm(action.payload)
          : null,
      };

    case SET_SECURITY_CAMERA_CONFIGURATION_FORM_VALUE:
      return {
        ...state,
        configurationForm: {
          ...state.configurationForm,
          [action.payload.name]: action.payload.value,
        },
      };

    case SET_SECURITY_CAMERA_CAMERAS_FORM:
      return {
        ...state,
        camerasForm: action.payload ? makeCamerasForm(action.payload) : null,
      };

    case SET_SECURITY_CAMERA_CAMERAS_FORM_VALUE:
      return {
        ...state,
        camerasForm: {
          ...state.camerasForm,
          cameras: state.camerasForm.cameras.map((camera, index) =>
            index === action.payload.index
              ? { ...camera, [action.payload.name]: action.payload.value }
              : camera
          ),
        },
      };

    case SET_SECURITY_CAMERA_PERMISSIONS_FORM:
      return {
        ...state,
        permissionsForm: action.payload
          ? makePermissionsForm(action.payload)
          : null,
      };

    case SET_SECURITY_CAMERA_PERMISSIONS_FORM_VALUE:
      return {
        ...state,
        permissionsForm: {
          ...state.permissionsForm,
          [action.payload.name]: action.payload.value,
        },
      };

    case SET_SHOW_TRUE_LOOK_IFRAME:
      return {
        ...state,
        showTrueLookIframe: action.payload.showTrueLookIframe,
      };

    case SET_SHOW_CHECKVIDEO_LOOK_IFRAME:
      return {
        ...state,
        showCheckvideoIframe: action.payload.showCheckvideoIframe,
      };

    default:
      return state;
  }
}
