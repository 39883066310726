import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import RfaLogTable from './RfaLogTable';
import RfaSearchFilter from './RfaSearchFilter';
import ModalRfaLogPdf from './ModalRfaLogPdf';
import ModalRfaLogEmail from './ModalRfaLogEmail';
import ModalRfaLogDeleteConfirmation from './ModalRfaLogDeleteConfirmation';
import ModalRfaCreateOptions from './ModalRfaCreateOptions';
import ModalRfa from './ModalRfa';
import RfaFilters from './RfaFilters';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
  BUTTON_ICONS,
} from 'components/shared/button/Button';
import NavigationBar from 'components/shared/navigation/NavigationBar';

import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import {
  getRfaTabNavigation,
  getRfaFilters,
  getRfaDataChanged,
} from 'components/admin/projects/details/project-rfa-log/store/selectors';

import {
  setRfaTabNavigation,
  loadRfasRequest,
  loadRfasLogPdfRequest,
  startNewRfa,
} from 'components/admin/projects/details/project-rfa-log/store/actions';
import { getShowEmailModal } from 'components/admin/projects/details/project-email-modal/store/selectors';

import { rfaTabs, PRFA_TAB, RFA_TAB } from 'domain/rfa';
import RfaPermissions from 'permissions/rfa-permissions';

import './ProjectRfaLog.css';
import { openRfa } from './store/actions';

const ProjectRfaLog = (props) => {
  const {
    dispatch,
    renderHeaders,
    currentUser,
    currentProject,
    currentFilters,
    tabNavigation,
    dataChanged,
    match,
    history,
    showEmailModal,
  } = props;

  const { projectId, itemId } = match.params;
  const subsection = match.params.subsection || 'rfa';

  useEffect(() => {
    // Open the Rfa modal if url has an itemId and if the mail modal is closed
    // to avoid conflicts
    if (itemId && !showEmailModal) {
      dispatch(openRfa(projectId, itemId, true));
    }
  }, [itemId]);

  if (!currentProject) return null;

  const canSubmit = RfaPermissions.canSubmit(currentUser, currentProject);

  const getTabNavigationFromSubsection = (subsection) => {
    switch (subsection) {
      case 'p-rfa':
        return PRFA_TAB;
      case 'rfa':
        return RFA_TAB;
      default:
        return RFA_TAB;
    }
  };

  const getSubsectionFromTabNavigation = (tabNavigation) => {
    switch (tabNavigation) {
      case PRFA_TAB:
        return 'p-rfa';
      case RFA_TAB:
        return 'rfa';
      default:
        return 'rfa';
    }
  };

  const handleFilterChange = (filters) => {
    dispatch(loadRfasRequest(currentProject.id, filters));
  };
  const handleTabNavChange = (item) => {
    dispatch(setRfaTabNavigation(item));
    handleFilterChange({ pre: item === PRFA_TAB });

    const subsectionValue = getSubsectionFromTabNavigation(item);
    history.push(
      `/admin/projects/${currentProject.id}/rfas/${subsectionValue}`
    );
  };
  const handleAdd = () => dispatch(startNewRfa(currentUser, currentProject));
  const handleViewPdfLogs = () => {
    dispatch(loadRfasLogPdfRequest(currentProject.id, currentFilters.pre));
  };

  useEffect(() => {
    if (!canSubmit) currentFilters.pre = null;

    handleFilterChange(currentFilters);
  }, []);

  useEffect(() => {
    if (!dataChanged) return;

    handleFilterChange(currentFilters);
  }, [dataChanged]);

  useEffect(() => {
    if (!canSubmit) currentFilters.pre = null;

    const tabNavValue = getTabNavigationFromSubsection(subsection);
    dispatch(setRfaTabNavigation(tabNavValue));
    handleFilterChange({ pre: tabNavValue === PRFA_TAB });
  }, [subsection]);

  const headerButtons = [
    <RfaSearchFilter
      key="search-rfa"
      handleFilterChange={handleFilterChange}
    />,
    <Button
      key="add-rfa"
      type={BUTTON_TYPES.LINK}
      icon={BUTTON_ICONS.PLUS}
      label="Add RFA"
      onClick={handleAdd}
    />,
    <Button
      key="view-rfa-pdf-log"
      type={BUTTON_TYPES.LINK}
      color={BUTTON_COLORS.GREEN}
      label="View Log"
      onClick={handleViewPdfLogs}
    />,
  ];

  const navigationBar = canSubmit && (
    <div className="rfa-log-header">
      <NavigationBar
        items={rfaTabs}
        selectedValue={tabNavigation}
        onSelected={handleTabNavChange}
      />
    </div>
  );

  return (
    <div className="rfa-log-content-container">
      <div style={{ marginLeft: 20, paddingTop: 20 }}>
        {renderHeaders(headerButtons)}
      </div>
      {navigationBar}
      <RfaLogTable handleFilterChange={handleFilterChange} />
      <ModalRfaLogPdf />
      <ModalRfaLogEmail />
      <ModalRfaLogDeleteConfirmation />
      <ModalRfaCreateOptions />
      <ModalRfa />
      <RfaFilters handleFilterChange={handleFilterChange} />
    </div>
  );
};

export default withRouter(
  connect((state) => {
    return {
      currentUser: getCurrentUser(state),
      currentProject: getCurrentProject(state),
      currentFilters: getRfaFilters(state),
      tabNavigation: getRfaTabNavigation(state),
      dataChanged: getRfaDataChanged(state),
      showEmailModal: getShowEmailModal(state),
    };
  })(ProjectRfaLog)
);
